@font-face {
  font-family: 'Messina Sans';
  font-weight: 400;
  font-style: normal;
  src: local('Messina'), url(./fonts/MessinaSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Messina Sans';
  font-weight: 700;
  src: local('Messina'), url(./fonts/MessinaSans-Bold.ttf) format('truetype');
}

html,
body {
  margin: 0;
  min-width: 1024px;
  height: 100%;
  font-family: 'Messina Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div#root {
  height: 100%;
}
